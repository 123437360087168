import { useMutation } from "@tanstack/react-query";

import { useMe } from "../../../hooks/queries";
import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";

const useDeleteInstagramIntegration = (
  locationId: string,
  locationData: LocationData | undefined
) => {
  const { data: me } = useMe();
  const deleteInstagramIntegrationMutation = useMutation((payload: any) => {
    return DataSvc.deleteInstagramIntegration(
      payload.locationId,
      payload.userId
    );
  });

  const deleteInstagramIntegration = () => {
    if (locationData) {
      deleteInstagramIntegrationMutation
        .mutateAsync({
          locationId,
          userId: `${me?.id}`
        })
        .then(() => {
          locationData.locations_instagram_integrations = [];
        });
    }
  };

  return { deleteInstagramIntegration };
};

export default useDeleteInstagramIntegration;
