import React from "react";
import { useTranslation } from "react-i18next";

import { CreateLocalPostState, LocalPostTopicType } from "../utils";

export interface IGenerateAiTextButtonProps {
  onClick: () => void;
  localPostState: CreateLocalPostState;
}

export const calculateIsEnabled = (localPostState: CreateLocalPostState) => {
  const hasMedia = localPostState.media.length > 0;
  const hasGeminiPrompt = localPostState.geminiPrompt.length > 0;
  const hasFullInfo =
    localPostState.topicType === LocalPostTopicType.STANDARD ||
    // Event / Offer types
    (localPostState.event.title &&
      localPostState.event.schedule.startDate &&
      localPostState.event.schedule.endDate);

  return (hasMedia || hasGeminiPrompt) && hasFullInfo;
};

export const GenerateAiTextButton: React.FunctionComponent<
  IGenerateAiTextButtonProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.${key}`
    );
  const { onClick, localPostState } = props;

  return (
    <button
      className={`btn btn-blue ${
        calculateIsEnabled(localPostState) ? "" : "disabled"
      }`}
      onClick={onClick}
    >
      {t("generate_text_with_ai_from_photo_and_details")}
    </button>
  );
};
