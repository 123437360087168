import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { MassPostForm } from "../../components/MassPostPage/MassPostForm";
import { useMeWithOrganizationImpersonation } from "../../hooks/organizationImpersonation";
import { useValidateOrganization } from "../../hooks/validateUserOrganization";

import "./styles.scss";

type IMassPostPageProps = RouteComponentProps<any>;

const MassPostPage: React.FunctionComponent<IMassPostPageProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`massPostPage.${key}`);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const me = useMeWithOrganizationImpersonation();
  const matchOrganizationId = props.match.params.organizationId;

  useValidateOrganization({
    matchOrganizationId,
    redirectUrlFormat: (orgId) => `/massPost/organization/${orgId}`,
  });

  return (
    <div className="right-content mass-post-page">
      <div className="top-title-bar flex-grid">
        <div className="left-title">{t("mass_post_page")}</div>
      </div>
      {me && (
        <MassPostForm organizationId={matchOrganizationId} userId={me.id} />
      )}
    </div>
  );
};

export default withRouter(MassPostPage);
