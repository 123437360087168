import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { InternalToolContainer } from "../../components/InternalTools/InternalToolsContainer";
import { InternalToolsContextProvider } from "../../components/InternalTools/InternalToolsContextProvider";
import { InternalToolEnum } from "../../components/InternalTools/InternalToolsDictionaryAndTypes";
import { useValidateOrganization } from "../../hooks/validateUserOrganization";

import "./styles.scss";

type IOrgToolsPageProps = RouteComponentProps<any>;

const OrgToolsPage: React.FunctionComponent<IOrgToolsPageProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`orgToolsPage.${key}`);

  const internalToolsInUse = [InternalToolEnum.MetricsAreaNew];
  const [organizationId, setOrganizationId] = useState<string>("");

  useValidateOrganization({
    matchOrganizationId: props.match.params.organizationId,
    setOrganizationId,
    redirectUrlFormat: (orgId) => `/orgTools/organization/${orgId}`,
  });

  return (
    <InternalToolsContextProvider
      defaultTools={internalToolsInUse}
      toolsToExpose={internalToolsInUse}
    >
      <div className="right-content org-tool">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("OrgTools")}</div>
          <div className="line"></div>
        </div>
        <InternalToolContainer
          initialGoogleInformation={undefined}
          orgId={
            isNaN(Number(organizationId)) ? undefined : Number(organizationId)
          }
          overlayTextKey=""
        />
      </div>
    </InternalToolsContextProvider>
  );
};

export default withRouter(OrgToolsPage);
