import { useQueryClient } from "@tanstack/react-query";

const useRefetchLocationData = (locationId: string) => {
  const queryClient = useQueryClient();

  const refetchLocationData = () =>
    queryClient.invalidateQueries({
      queryKey: ["location", locationId]
    });

  return { refetchLocationData };
};

export default useRefetchLocationData;
