import React from "react";
import { useTranslation } from "react-i18next";

import { useDateTranslation } from "../../../hooks/dateTranslation";
import {
  LocationReviewSummaryHistory,
  LocationReviewSummaryStatus,
} from "../../../models/LocationReviewSummary";
import DropdownSelect from "../../FormElement/DropdownSelect";

import "./styles.scss";

type IReviewSummarySelectorProps = {
  history: LocationReviewSummaryHistory[] | undefined;
  selectedId: bigint | undefined;
  onSelectId: (id: bigint | undefined) => void;
};

export const ReviewSummarySelector: React.FunctionComponent<
  IReviewSummarySelectorProps
> = ({ history, selectedId, onSelectId }) => {
  const { translateDateRange } = useDateTranslation();
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityReviewSummariesPage.dropdown_selector.${key}`);

  const getStatus = (status: LocationReviewSummaryStatus) => {
    switch (status) {
      case LocationReviewSummaryStatus.NOT_ENOUGH_REVIEWS:
        return ` ${t("not_enough_reviews")}`;
      case LocationReviewSummaryStatus.SUCCESS:
        return "";
      default:
        return ` ${t("error_happen")}`;
    }
  };

  // Hack to use DropdownSelect, which only accepts an array of strings as items
  // So we map the timespan to an id, and return the id in the onChange handler
  const timespanIdMap: Record<string, bigint> = {};
  for (const h of history ?? []) {
    timespanIdMap[
      `${translateDateRange(
        // Cast to Date because the data is serialized as a string
        new Date(h.start_date),
        new Date(h.end_date)
      )} ${getStatus(h.status)}`
    ] = h.id;
  }

  const items = Object.keys(timespanIdMap);

  const onChange = (timespan: string) => {
    const id = timespanIdMap[timespan];
    // Return the id associated with the timespan
    onSelectId(id);
  };

  const selectedTimespan =
    items.find((item) => {
      return Number(timespanIdMap[item]) === Number(selectedId);
    }) ?? "";

  const getStatusColor = (item: string) => {
    const id = timespanIdMap[item];
    return history?.find((h) => h.id === id)?.status ===
      LocationReviewSummaryStatus.SUCCESS
      ? "#1f1f1f"
      : "#aaa";
  };

  return (
    <div className="review-summary-selector">
      <DropdownSelect
        value={selectedTimespan}
        items={items}
        onChange={onChange}
        render={(item) => (
          <span style={{ color: `${getStatusColor(item)}` }}>{item}</span>
        )}
      />
    </div>
  );
};
