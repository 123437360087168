import { useQuery } from "@tanstack/react-query";
import { subDays, isBefore } from "date-fns";

import DataSvc from "../../../services/dataSvc";

const useLocationRecommendations = (
  locationId: string,
  organizationId: string
) => {
  // get the latest recommendation history
  const { data: latestRecommendationHistory } = useQuery(
    ["latest-recommendation-history", locationId],
    async () => {
      return await DataSvc.getLocationRecommendationHistory(
        organizationId,
        locationId,
        1
      );
    }
  );

  // get latest recommendations for the location
  const { data: locationRecommendations } = useQuery(
    ["location-recommendations", locationId, latestRecommendationHistory],
    () => {
      if (latestRecommendationHistory?.histories?.length > 0) {
        const lastGeneratedDate =
          latestRecommendationHistory?.histories[0].generated_date;
        if (lastGeneratedDate) {
          const oneWeekBefore = subDays(new Date(), 8); // Use 8 for timezone difference
          if (isBefore(oneWeekBefore, new Date(lastGeneratedDate))) {
            return DataSvc.getRecommendations(
              {
                location_id: locationId,
                generated_date: lastGeneratedDate
              },
              false
            );
          }
        }
      }
      return [];
    }
  );

  return { locationRecommendations };
};

export default useLocationRecommendations;
