import React from "react";
import { useTranslation } from "react-i18next";

import { CommentsSection } from "./CommentsSection";
import { DownloadReviewsButtonsSection } from "./IDownloadReviewsButtonsSection";
import {
  LocationReviewSummary,
  LocationReviewSummaryStatus,
} from "../../../models/LocationReviewSummary";
import { ReviewSummaryCommentsChart } from "../../FacilityDetailsComponents/ReviewSummary/ReviewSummaryCommentsChart";
import { ReviewSummarySummarySection } from "../../FacilityDetailsComponents/ReviewSummary/ReviewSummarySummarySection";

import "./styles.scss";

type IExtendedReviewSummaryProps = {
  locationTitle: string | undefined;
  reviewSummary: LocationReviewSummary | undefined;
};

export const ExtendedReviewSummary: React.FunctionComponent<
  IExtendedReviewSummaryProps
> = ({ locationTitle, reviewSummary }) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: any) =>
    _t(`facilityReviewSummariesPage.extendedReviewSummary.${key}`, options);

  if (
    !reviewSummary ||
    reviewSummary.status !== LocationReviewSummaryStatus.SUCCESS
  ) {
    return (
      <div className="extended-review-summary">
        <div className="summary-section section white-border-panel">
          <div className="content">
            <div className="content-section">
              <span className="section-title">{t("summary")}</span>
              <ReviewSummarySummarySection summary={reviewSummary} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="extended-review-summary">
      <div className="summary-section section white-border-panel">
        <div className="content">
          <div className="content-section">
            <span className="section-title">{t("summary")}</span>
            <p>{reviewSummary?.summary?.summary}</p>
          </div>
          <div className="content-section">
            <span className="section-title">{t("improvements")}</span>
            {reviewSummary?.summary?.improvements?.length === 0 && (
              <span>{t("no_improvements")}</span>
            )}
            <ul>
              {reviewSummary?.summary?.improvements?.map((improvement) => (
                <li key={improvement}>{improvement}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="chart-section">
          <ReviewSummaryCommentsChart data={reviewSummary} />
        </div>
      </div>
      <div className="comments-section">
        <CommentsSection reviewSummary={reviewSummary} type="positive" />
        <CommentsSection reviewSummary={reviewSummary} type="negative" />
      </div>
      <DownloadReviewsButtonsSection
        locationTitle={locationTitle}
        reviewSummary={reviewSummary}
      />
    </div>
  );
};
