import React from "react";
import { useTranslation } from "react-i18next";

import {
  ADMIN_MENU_ITEMS,
  ORG_ADMIN_MENU_ITEMS,
  LOC_ADMIN_MENU_ITEMS,
  ADMIN_AS_ORG_ADMIN_MENU_ITEMS,
} from "./const";
import { useGhostOrganization } from "./hooks/useGhostOrganization";
import { converMenuItemToElement } from "./utils";
import { useMeWithOrganizationImpersonation } from "../../hooks/organizationImpersonation";
import { AccountData, AccountType } from "../../models/AccountData";

import "./styles.scss";

const selectMenuItems = (me?: AccountData) => {
  if (!me) return [];

  if (me.type === AccountType.ADMIN && me?.is_impersonating) {
    return ADMIN_AS_ORG_ADMIN_MENU_ITEMS;
  } else if (me.type === AccountType.ADMIN) {
    return ADMIN_MENU_ITEMS;
  } else if (me.type === AccountType.ORG_ADMIN) {
    return ORG_ADMIN_MENU_ITEMS;
  } else if (me.type === AccountType.LOC_ADMIN) {
    return LOC_ADMIN_MENU_ITEMS;
  }

  return [];
};

export const LeftMenu: React.FunctionComponent = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`leftMenu.ghost_mode.${key}`);

  // TODO: Fix me
  const me = useMeWithOrganizationImpersonation();
  const ghostOrganization = useGhostOrganization(me);
  const menuItems = selectMenuItems(me);

  return (
    <div className="left-nav">
      {/* eslint-disable-next-line  */}
      <a className="icons top-arrow"></a>
      {ghostOrganization && (
        <>
          <div className="ghost-mode-header">
            <h3>{t("ghost_mode_active")}</h3>
            <p>
              <strong>{ghostOrganization.name}</strong>
              <br />
              {t("currently_ghosting")}
            </p>
          </div>
          <hr />
        </>
      )}
      <ul>{menuItems.map((item) => converMenuItemToElement(item, me))}</ul>
    </div>
  );
};

export default LeftMenu;
