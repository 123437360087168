import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { LocationReviewSummary } from "../../../../models/LocationReviewSummary";
import DataSvc from "../../../../services/dataSvc";
import { downloadCsv } from "../../../../utils/csvUtils";

import "./styles.scss";

type IDownloadReviewsButtonsSectionProps = {
  locationTitle?: string;
  reviewSummary: LocationReviewSummary;
};

export const DownloadReviewsButtonsSection: React.FunctionComponent<
  IDownloadReviewsButtonsSectionProps
> = ({ locationTitle, reviewSummary }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `facilityReviewSummariesPage.extendedReviewSummary.downloadReviewsButton.${key}`
    );

  const { id: summaryId, location_id } = reviewSummary;

  const downloadReviews = async (forWindows: boolean) => {
    const reviews = await DataSvc.getLocationReviewSummaryReviews(
      Number(location_id),
      Number(summaryId)
    );

    const header = ["日付", "投稿者", "点数", "文章", "言語"].join(",");

    const processedReviews = (reviews as any[]).map(
      ({ review_date, rating, comment, language, reviewer_name }) => {
        return [
          review_date.split("T")[0],
          reviewer_name,
          rating,
          comment,
          language,
        ].join(",");
      }
    );

    const csvRows = [header, ...processedReviews];

    const chartTitle = `${locationTitle}-${t("review_summary")}: ${
      `${reviewSummary.start_date}`.split("T")[0]
    } - ${`${reviewSummary.end_date}`.split("T")[0]}`;

    downloadCsv(csvRows, chartTitle, forWindows);
    ReactGA.event({
      category: "review-summaries",
      action: "download-reviews",
    });
  };

  return (
    <div className="download-reviews-button white-border-panel section">
      <h2 className="section-title">{t("download_relevant_reviews")}</h2>
      <div className="download-reviews-button__buttons">
        <button className="btn btn-blue" onClick={() => downloadReviews(false)}>
          {t("download_reviews")}
        </button>
        <button className="btn btn-blue" onClick={() => downloadReviews(true)}>
          {t("download_reviews")}(Windows)
        </button>
      </div>
    </div>
  );
};
