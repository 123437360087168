export enum LeftMenuItem {
  adminLocations = "adminLocations",
  adminOrganizations = "adminOrganizations",
  adminFunctionManagement = "adminFunctionManagement",
  adminInternalTools = "adminInternalTools",
  adminDeleteGoogleLink = "adminDeleteGoogleLink",
  adminMassSendEmails = "adminMassSendEmails",
  adminReturnToAdminMain = "adminReturnToAdminMain",
  orgDashboard = "orgDashboard",
  orgMassPost = "orgMassPost",
  orgAnalysisCollections = "orgAnalysisCollections",
  orgRecommendations = "orgRecommendations",
  orgRecMessages = "orgRecMessages",
  orgTools = "orgTools",
  facilityList = "facilityList",
  accountSettings = "accountSettings",
  manageLocations = "manageLocations"
}

export const ADMIN_MENU_ITEMS: LeftMenuItem[] = [
  LeftMenuItem.adminLocations,
  LeftMenuItem.adminOrganizations,
  LeftMenuItem.adminFunctionManagement,
  LeftMenuItem.adminInternalTools,
  LeftMenuItem.adminDeleteGoogleLink,
  LeftMenuItem.adminMassSendEmails
];

export const ORG_ADMIN_MENU_ITEMS: LeftMenuItem[] = [
  LeftMenuItem.orgDashboard,
  LeftMenuItem.orgMassPost,
  LeftMenuItem.facilityList,
  LeftMenuItem.orgAnalysisCollections,
  LeftMenuItem.orgRecommendations,
  LeftMenuItem.orgRecMessages,
  LeftMenuItem.orgTools,
  LeftMenuItem.accountSettings,
  LeftMenuItem.manageLocations
];

export const ADMIN_AS_ORG_ADMIN_MENU_ITEMS: LeftMenuItem[] = [
  LeftMenuItem.orgDashboard,
  LeftMenuItem.orgMassPost,
  LeftMenuItem.orgAnalysisCollections,
  LeftMenuItem.facilityList,
  LeftMenuItem.orgRecommendations,
  LeftMenuItem.orgRecMessages,
  LeftMenuItem.orgTools,
  LeftMenuItem.adminReturnToAdminMain
];

export const LOC_ADMIN_MENU_ITEMS: LeftMenuItem[] = [
  LeftMenuItem.facilityList,
  LeftMenuItem.accountSettings,
  LeftMenuItem.manageLocations
];
