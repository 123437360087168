import { useReducer, Reducer } from "react";

import i18n from "../../../../i18n";
import { isUrlValid } from "../../../../utils/formatValidation";
import { DateTimeInterval } from "../../../FormElement/DateTimeIntervalInput/utils";
import {
  ActionType,
  CreateLocalPostState,
  CreateLocalPostAction,
  CreateLocalPostActionTypes,
  LocalPostTopicType,
  EventScheduleError,
  validateBody,
  validateCallToActionUrl
} from "../utils";

export const t = (key: string) =>
  i18n.t(
    `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.errors.${key}`
  );

const originalFormState: CreateLocalPostState = {
  media: [],
  text: "",
  messageTextError: undefined,
  languageCode: "ja",
  callToAction: {
    actionType: ActionType.ACTION_TYPE_UNSPECIFIED,
    url: "",
    urlError: undefined,
  },
  topicType: LocalPostTopicType.STANDARD,
  geminiPrompt: "",
  event: {
    title: "",
    schedule: {
      startDate: undefined,
      startTime: undefined,
      endDate: undefined,
      endTime: undefined,
    },
    scheduleError: {
      dateError: undefined,
      timeError: undefined,
    },
  },
  offer: {
    couponCode: "",
    redeemOnlineUrl: "",
    termsConditions: "",
  },
  postToInstagram: false,
};

export const useCreateLocalPostReducer = () => {
  const [state, dispatch] = useReducer<
    Reducer<CreateLocalPostState, CreateLocalPostAction>
  >(createLocalPostStateReducer, originalFormState);

  const onChangeTopicType = (topicType: LocalPostTopicType) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_TOPIC_TYPE,
      payload: topicType,
    });
  };

  const onChangeMedia = (files: File[]) => {
    dispatch({ type: CreateLocalPostActionTypes.CHANGE_MEDIA, payload: files });
  };

  const onChangeMediaError = (error: string | undefined) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_MEDIA_ERROR,
      payload: error,
    });
  };

  const onChangeEventTitle = (eventTitle: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_EVENT_TITLE,
      payload: eventTitle,
    });
  };

  const onChangeEventSchedule = (eventSchedule: DateTimeInterval) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE,
      payload: eventSchedule,
    });
  };

  const onChangeEventScheduleError = (
    eventScheduleError: EventScheduleError
  ) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE_ERROR,
      payload: eventScheduleError,
    });
  };

  const onChangeBody = (body: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_BODY,
      payload: body,
    });
  };

  const onChangeOfferCouponCode = (couponCode: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_OFFER_COUPON_CODE,
      payload: couponCode,
    });
  };

  const onChangeOfferRedeemOnlineUrl = (redeemOnlineUrl: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_OFFER_REDEEM_ONLINE_URL,
      payload: redeemOnlineUrl,
    });
  };

  const onChangeOfferTermsAndConditions = (termsAndConditions: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_OFFER_TERMS_CONDITIONS,
      payload: termsAndConditions,
    });
  };

  const onChangeCallToActionType = (actionType: ActionType) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_TYPE,
      payload: actionType,
    });
  };

  const onChangeCallToActionUrl = (url: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_URL,
      payload: url,
    });
  };

  const onChangePostToInstagram = (postToInstagram: boolean) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_POST_TO_INSTAGRAM,
      payload: postToInstagram,
    });
  };

  const onChangePostBodyText = (newText: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_BODY,
      payload: newText,
    });
  }

  const onChangeGeminiPrompt = (geminiPrompt: string) => {
    dispatch({
      type: CreateLocalPostActionTypes.CHANGE_GEMINI_PROMPT,
      payload: geminiPrompt,
    });
  }

  return { state, dispatch, onChangeTopicType, onChangeMedia, onChangeMediaError, onChangeEventTitle, onChangeEventSchedule, onChangeEventScheduleError, onChangeBody, onChangeOfferCouponCode, onChangeOfferRedeemOnlineUrl, onChangeOfferTermsAndConditions, onChangeCallToActionType, onChangeCallToActionUrl, onChangePostToInstagram, onChangePostBodyText, onChangeGeminiPrompt };
}


const createLocalPostStateReducer = (
  state: CreateLocalPostState,
  action: CreateLocalPostAction
): CreateLocalPostState => {
  switch (action.type) {
    case CreateLocalPostActionTypes.CHANGE_TOPIC_TYPE:
      return { ...state, topicType: action.payload };
    case CreateLocalPostActionTypes.CHANGE_MEDIA:
      return {
        ...state,
        media: action.payload,
        messageTextError: validateBody(state.text, action.payload),
        postToInstagram: state.postToInstagram && action.payload.length > 0,
      };
    case CreateLocalPostActionTypes.CHANGE_MEDIA_ERROR:
      return { ...state, mediaError: action.payload };
    case CreateLocalPostActionTypes.CHANGE_EVENT_TITLE:
      return {
        ...state,
        event: {
          ...state.event,
          title: action.payload,
          titleError:
            action.payload.length < 1
              ? t("event.title_too_short")
              : action.payload.length >= 58
              ? t("event.title_too_long")
              : undefined,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE:
      const newSchedule = { ...action.payload };
      if (newSchedule.startTime === undefined) {
        delete newSchedule.startTime;
      }
      if (newSchedule.endTime === undefined) {
        delete newSchedule.endTime;
      }
      return {
        ...state,
        event: { ...state.event, schedule: newSchedule },
      };
    case CreateLocalPostActionTypes.CHANGE_EVENT_SCHEDULE_ERROR:
      return {
        ...state,
        event: { ...state.event, scheduleError: action.payload },
      };
    case CreateLocalPostActionTypes.CHANGE_BODY:
      return {
        ...state,
        text: action.payload,
        messageTextError: validateBody(action.payload, state.media),
      };
    case CreateLocalPostActionTypes.CHANGE_OFFER_COUPON_CODE:
      return {
        ...state,
        offer: {
          ...state.offer,
          couponCode: action.payload,
          couponCodeError:
            action.payload.length >= 58
              ? t("offer.coupon_code_too_long")
              : undefined,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_OFFER_REDEEM_ONLINE_URL:
      let redeemOnlineUrlError = undefined;
      if (action.payload !== "" && !isUrlValid(action.payload)) {
        redeemOnlineUrlError = t("offer.url");
      }

      return {
        ...state,
        offer: {
          ...state.offer,
          redeemOnlineUrl: action.payload,
          redeemOnlineUrlError,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_OFFER_TERMS_CONDITIONS:
      return {
        ...state,
        offer: {
          ...state.offer,
          termsConditions: action.payload,
          termsAndConditionsError:
            action.payload.length > 5000
              ? t("offer.terms_and_conditions_too_long")
              : undefined,
        },
      };
    case CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_TYPE:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          actionType: action.payload,
          urlError: validateCallToActionUrl({
            actionType: action.payload as ActionType,
            url: state.callToAction.url,
          }),
        },
      };
    case CreateLocalPostActionTypes.CHANGE_CALL_TO_ACTION_URL:
      return {
        ...state,
        callToAction: {
          ...state.callToAction,
          url: action.payload,
          urlError: validateCallToActionUrl({
            actionType: state.callToAction.actionType,
            url: action.payload,
          }),
        },
      };
    case CreateLocalPostActionTypes.CHANGE_POST_TO_INSTAGRAM:
      return { ...state, postToInstagram: action.payload };
    case CreateLocalPostActionTypes.CHANGE_GEMINI_PROMPT:
      return { ...state, geminiPrompt: action.payload };
    default:
      console.error(`Unhandled action type ${action.type}`);
      return state;
  }
};