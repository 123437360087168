import React from "react";
import { useTranslation } from "react-i18next";

import useUnrepliedReviewsList from "./hooks/useUnrepliedReviewsList";
import { ReviewData } from "../../../models/ReviewData";
import UnRepliedReviewItem from "../UnRepliedReviewItem";

import "./styles.scss";
export interface IModalUnRepliedReviewsProps {
  locationId: string;
  onClose: () => void;
}

export const ModalUnRepliedReviews: React.FunctionComponent<
  IModalUnRepliedReviewsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.modalUnRepliedReviews.${key}`);
  const { onClose, locationId } = props;

  const { unRepliedReviewList, status, onDeleteReviewItem } =
    useUnrepliedReviewsList(locationId);

  const renderUnRepliedReviews = () => {
    if (status === "loading") {
      return <div className="loading">{t("loading")}</div>;
    }

    if (!unRepliedReviewList || unRepliedReviewList?.length === 0) {
      return <div className="no-comment">{t("noCommentNeedReply")}</div>;
    }

    return unRepliedReviewList.map((review: ReviewData) => (
      <UnRepliedReviewItem
        key={review.id}
        review={review}
        onDelete={onDeleteReviewItem}
      />
    ));
  };

  return (
    <div className="modal modal-default modal-unreplied-reviews">
      <div className="modal-mains">
        <div className="btn-close" onClick={onClose}></div>
        <b className="title">{t("listOfNotReplied")}</b>
        <div className="review-container">{renderUnRepliedReviews()}</div>
        <div className="bottom-btns">
          <div className="btn btn-border" onClick={onClose}>
            {t("back")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUnRepliedReviews;
