import React from "react";
import { useTranslation } from "react-i18next";

import {
  ImpersonateStatus,
  useImpersonate,
} from "../../../../hooks/impersonate";
import {
  LocationReviewSummary,
  LocationReviewSummaryStatus,
} from "../../../../models/LocationReviewSummary";
import { ColoredPieChart } from "../../../Common/ColoredPieChart";

import "./styles.scss";

type IReviewSummaryCommentsChartProps = {
  data: LocationReviewSummary | undefined;
};

export const ReviewSummaryCommentsChart = ({
  data,
}: IReviewSummaryCommentsChartProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string, params = {}) =>
    _t(`facilityDetailsPage.reviewSummary.reviewSummaryCommentsChart.${key}`, {
      ...params,
    });

  const { impersonateStatus } = useImpersonate();

  if (!data || data.status !== LocationReviewSummaryStatus.SUCCESS) {
    return null;
  }

  return (
    <div className="review-summary-comments-chart-container">
      <ColoredPieChart
        data={[
          {
            name: "Positive",
            value: data?.summary?.positiveComments?.count ?? 0,
            fill: "#7AC63F",
          },
          {
            name: "Negative",
            value: data?.summary?.negativeComments?.count ?? 0,
            fill: "#96001B",
          },
        ]}
        label={
          impersonateStatus === ImpersonateStatus.IMPERSONATE
            ? (data?.summary?.negativeComments?.count ?? 0) +
              (data?.summary?.positiveComments?.count ?? 0)
            : undefined
        }
      />
      <div className="legend-container">
        <span className="legend-positive">{t("positive_comments")}</span>
        <span className="legend-negative">{t("negative_comments")}</span>
      </div>
    </div>
  );
};
