import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { SummaryLocation } from "../../../containers/SendMessageScreens";
import {
  FacilityFilterConditionData,
  getProfBoolQueryObject,
  getScoreArrayString,
  hasConditionFilter,
} from "../../../models/FacilityFilterConditionData";
import DataSvc from "../../../services/dataSvc";
import { createNestedFormData } from "../../../utils/formData";
import { CreateLocalPostForm } from "../../FacilityDetailsComponents/CreateLocalPostForm";
import { CreateLocalPostState } from "../../FacilityDetailsComponents/CreateLocalPostForm/utils";
import ModalFilterCondition from "../../FacilityListComponents/ModalFilterCondition";
import LocationsDisplay from "../../SendMessageComponents/LocationsDisplay";

import "./styles.scss";

interface IMassPostForm {
  organizationId: string;
  userId: number;
}

export const MassPostForm: React.FunctionComponent<IMassPostForm> = (
  props: IMassPostForm
) => {
  const { organizationId, userId } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`massPostPage.${key}`);

  const history = useHistory();

  const defaultConditionFormData = {
    healthIndex: [
      { key: "A", name: "健康指数 A", checked: true },
      { key: "B", name: "健康指数 B", checked: true },
      { key: "C", name: "健康指数 C", checked: true },
      { key: "D", name: "健康指数 D", checked: true },
    ],
    municipalities: [],
    categories: [],
    labels: [],
    profBooleans: [],
  };

  const [selectedLocations, setSelectedLocations] = useState<SummaryLocation[]>(
    []
  );

  const [shownModalFilterCondition, setShownModalFilterCondition] =
    useState(false);

  const [conditionFormData, setConditionFormData] =
    useState<FacilityFilterConditionData>(defaultConditionFormData);

  // Query for locations that match the filter conditions
  const { data: filteredLocations } = useQuery(
    ["custom-message-locations", organizationId, conditionFormData],
    () => {
      const { labels, categories, municipalities } = conditionFormData;
      return DataSvc.getLocations(
        _.pickBy(
          {
            organization_id: organizationId ? [organizationId] : organizationId,
            seek_total_count: true,
            label_id: labels ? labels.map((label) => label.id) : null,
            category_id: categories
              ? categories.map((category) => category.id)
              : null,
            municipality_id: municipalities
              ? municipalities.flatMap((muniGroup) =>
                  muniGroup.muniList.map((muni) => muni.id)
                )
              : null,
            score: getScoreArrayString(conditionFormData),
            ...getProfBoolQueryObject(conditionFormData),
            ...(conditionFormData.hasLodging
              ? { has_lodging: conditionFormData.hasLodging.shouldInclude }
              : {}),
            summary_mode: true,
          },
          (value: any) => {
            return !_.isUndefined(value) && !_.isNull(value);
          }
        )
      );
    },
    { enabled: hasConditionFilter(conditionFormData) }
  );

  const onSelectedLocations = (locations: SummaryLocation[]) => {
    setSelectedLocations(locations);
  };

  const onApplyFilterCondition = (
    conditionFormData: FacilityFilterConditionData
  ) => {
    setConditionFormData(conditionFormData);
    setShownModalFilterCondition(false);
  };

  // Update selected locations when filtered locations change
  React.useEffect(() => {
    setSelectedLocations(filteredLocations?.list ?? []);
  }, [filteredLocations]);

  const post = async (value: CreateLocalPostState) => {
    value.locationIds = selectedLocations.map((location) => location.id);

    if (!value.locationIds || value.locationIds.length === 0) {
      toast.error(t("no_locations_selected"));
      return;
    }

    const { media, ...data } = value;

    const formData = createNestedFormData(data);

    media.forEach((_media) => {
      formData.append("media", _media);
    });

    ReactGA.event("Mass Local Post Posted", {
      category: "create-local-post",
      label: data.topicType,
      hasMedia: media.length > 0,
      hasText: data.text.length > 0,
      callToActionType: data.callToAction.actionType,
    });

    await DataSvc.massPostLocalPosts(formData);

    toast.success(t("posting_process_started"));
    // Redirect to dashboard
    history.push("");
  };

  return (
    <div className="mass-post-form">
      <div className="mass-post-locations-wrapper">
        <h3>{t("locations_to_post_to")}</h3>
        <div>
          <LocationsDisplay
            hideTopTitle
            hideFootnote
            isCustomFilterMode={true}
            onOpenFilters={() => {
              setShownModalFilterCondition(true);
            }}
            isSelectableLocations={true}
            onSelectedLocations={onSelectedLocations}
            locationData={filteredLocations?.list ?? []}
            conditionFormData={conditionFormData}
          />
        </div>
      </div>
      <div className="mass-post-contents-wrapper white-border-panel">
        <h2>{t("post_contents")}</h2>
        <CreateLocalPostForm
          locationData={undefined}
          post={post}
          locationNames={selectedLocations.map((location) => location.name)}
        />
      </div>
      {shownModalFilterCondition && (
        <ModalFilterCondition
          organizationId={organizationId || ""}
          userId={userId}
          onClose={() => {
            setShownModalFilterCondition(false);
          }}
          conditionFormData={conditionFormData}
          onApply={onApplyFilterCondition}
        />
      )}
    </div>
  );
};
