import { useMe } from "./queries";
import { AccountType } from "../models/AccountData";

export enum ImpersonateStatus {
  LOADING = "LOADING",
  NOT_IMPERSONATE = "NOT_IMPERSONATE",
  IMPERSONATE = "IMPERSONATE"
}

/**
 * Hook to check if the user is an admin impersonating. Can be expanded upon in the future if needed,
 * to possibly handle logic for switching users / etc.
 *
 * @returns {ImpersonateStatus} - Whether the user is impersonating or not. If the user is not loading, the status will be LOADING, because we don't know if they are impersonating or not yet.
 */
export const useImpersonate = () => {
  const { data: user } = useMe();

  let impersonateStatus;

  if (!user) {
    impersonateStatus = ImpersonateStatus.LOADING;
  } else if (user.type === AccountType.ADMIN) {
    impersonateStatus = ImpersonateStatus.IMPERSONATE;
  } else {
    impersonateStatus = ImpersonateStatus.NOT_IMPERSONATE;
  }

  return { impersonateStatus };
};
