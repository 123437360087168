import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";

import { LocalPostData } from "../../../models/LocalPostData";
import DataSvc from "../../../services/dataSvc";

const useLocationLocalPostsList = (locationId: string) => {
  const [recentLocalPostList, setRecentLocalPostList] = useState<
    LocalPostData[]
  >([]);

  const { data: localPostList } = useQuery(
    ["location.localPosts", locationId],
    () => {
      if (locationId) {
        return DataSvc.getLocalPosts({
          limit: 10,
          offset: 0,
          order: "desc",
          sort: "post_date",
          location_id: [locationId]
        });
      }
      return null;
    }
  );

  useEffect(() => {
    if (localPostList) {
      setRecentLocalPostList(localPostList);
    }
  }, [localPostList]);

  return { recentLocalPostList };
};

export default useLocationLocalPostsList;
