import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export interface ILeftMenuMenuProps {
  to: string;
  icon: string;
  translationKey: string;
}

export const LeftMenuLinkItem: React.FunctionComponent<ILeftMenuMenuProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`leftMenu.${key}`);

  const { to, icon, translationKey } = props;

  return (
    <li>
      <NavLink to={to} className={`nav-items`}>
        <i className={`icons ${icon}`}></i>
        <span className="txt">{t(translationKey)}</span>
      </NavLink>
    </li>
  );
};
