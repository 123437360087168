import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ModalMessageDetails } from "../../components/ModalMessageDetails";
import { RecMessageList } from "../../components/RecMessageList";
import { useValidateOrganization } from "../../hooks/validateUserOrganization";
import { RecMessageDataFromAPI } from "../../models/RecMessageData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IRecMessagesListPageProps = RouteComponentProps<any>;

const RecMessagesListPage: React.FunctionComponent<
  IRecMessagesListPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`recMessageListPage.${key}`);

  const [organizationId, setOrganizationId] = useState<string>("");

  const [activeMessage, setActiveMessage] = useState<
    RecMessageDataFromAPI | undefined
  >(undefined);

  const { data: recMessageData } = useQuery(
    ["recMessages", organizationId],
    () => {
      if (organizationId) {
        return DataSvc.getRecMessages(organizationId);
      }
      return [];
    }
  );

  useValidateOrganization({
    matchOrganizationId: props.match.params.organizationId,
    setOrganizationId,
    redirectUrlFormat: (orgId) => `/recMessages/organization/${orgId}`,
  });

  const onSelectMessage = (messageData: RecMessageDataFromAPI | undefined) => {
    setActiveMessage(messageData);
  };

  const onCloseModal = () => setActiveMessage(undefined);

  return (
    <>
      <div className="right-content rec-message-list">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("rec_messages")}</div>
          <div className="rights"></div>
        </div>

        <RecMessageList
          dataList={recMessageData || []}
          openRecMessage={onSelectMessage}
        />
      </div>
      {activeMessage && (
        <ModalMessageDetails message={activeMessage} onClose={onCloseModal} />
      )}
    </>
  );
};

export default withRouter(RecMessagesListPage);
