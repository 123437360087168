import React from "react";

import { ImpersonateStatus, useImpersonate } from "../../../hooks/impersonate";

interface IImpersonateOnlyProps {
  children?: React.ReactNode;
}

export const ImpersonateOnly: React.FunctionComponent<
  IImpersonateOnlyProps
> = ({ children }) => {
  const { impersonateStatus } = useImpersonate();
  return <>{impersonateStatus === ImpersonateStatus.IMPERSONATE && children}</>;
};
