import React, { useCallback, useContext, useState } from "react";

import { useMe } from "./queries";
import { AccountType } from "../models/AccountData";

const ImpersonatedOrganizationContext = React.createContext<string | null>(
  null
);
const UpdateImpersonatedOrganizationContext = React.createContext<
  (organization: string | null) => void
>(() => {});

export const useMeWithOrganizationImpersonation = () => {
  const { data: me } = useMe();
  const organizationIdToImpersonate = useContext(
    ImpersonatedOrganizationContext
  );

  if (me?.type === AccountType.ADMIN && organizationIdToImpersonate) {
    return {
      ...me,
      organization_id: organizationIdToImpersonate,
      is_impersonating: true,
    };
  } else {
    return me;
  }
};

export const useUpdateImpersonatedOrganizationContext = () => {
  return useContext(UpdateImpersonatedOrganizationContext);
};

interface IImpersonatedOrganizationContextProps {
  children?: React.ReactNode;
}

export const ImpersonatedOrgaizationProvider: React.FunctionComponent<
  IImpersonatedOrganizationContextProps
> = ({ children }) => {
  const [impersonatedOrganizationId, setImpersonatedOrganizationId] = useState<
    string | null
  >(null);

  const { data: me } = useMe();

  const setOrganizationToImpersonate = useCallback(
    (organizationId: string | null) => {
      if (me?.type === AccountType.ADMIN && organizationId) {
        setImpersonatedOrganizationId(organizationId);
      } else {
        setImpersonatedOrganizationId(null);
      }
    },
    [me]
  );

  return (
    <ImpersonatedOrganizationContext.Provider
      value={impersonatedOrganizationId}
    >
      <UpdateImpersonatedOrganizationContext.Provider
        value={setOrganizationToImpersonate}
      >
        {children}
      </UpdateImpersonatedOrganizationContext.Provider>
    </ImpersonatedOrganizationContext.Provider>
  );
};
