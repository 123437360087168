import React from "react";

import { LeftMenuItem } from "./const";
import { LeftMenuLinkItem } from "./MenuItem";
import { AccountData } from "../../models/AccountData";
import { OrgFeatures } from "../../models/FeatureUseLimitsData";
import {
  getFeatureUseStatus,
  FeatureUseStatus,
} from "../../utils/featureUtils";

interface ILeftMenuMenuGenerationProps {
  to?: string;
  toFunction?: (me: AccountData) => string;
  displayCondition?: (me: AccountData) => boolean;
  icon: string;
  translationKey: string;
}

export const generateLeftMenuProps = (
  menuItem: LeftMenuItem
): ILeftMenuMenuGenerationProps | undefined => {
  switch (menuItem) {
    case LeftMenuItem.adminLocations:
      return {
        to: "/admin/locations",
        icon: "icon-build",
        translationKey: "facility_management",
      };
    case LeftMenuItem.adminOrganizations:
      return {
        to: "/admin/organizations",
        icon: "icon-user-circle",
        translationKey: "organization_management",
      };
    case LeftMenuItem.adminFunctionManagement:
      return {
        to: "/admin/functionManagement",
        icon: "icon-user-circle",
        translationKey: "function_management",
      };
    case LeftMenuItem.adminInternalTools:
      return {
        to: "/admin/internalTools",
        icon: "icon-setting",
        translationKey: "internal_tools",
      };
    case LeftMenuItem.adminDeleteGoogleLink:
      return {
        to: "/admin/deleteGoogleLink",
        icon: "icon-trash",
        translationKey: "delete_google_link",
      };
    case LeftMenuItem.adminMassSendEmails:
      return {
        to: "/admin/massSendEmails",
        icon: "icon-email",
        translationKey: "email_users",
      };
    case LeftMenuItem.adminReturnToAdminMain:
      return {
        to: "/admin/organizations/",
        icon: "icon-build",
        translationKey: "return_to_admin_main",
      };
    case LeftMenuItem.orgDashboard:
      return {
        toFunction: ({ organization_id: orgId }) =>
          `/dashboard/organization/${orgId}`,
        icon: "icon-dashboard",
        translationKey: "dashboard",
      };
    case LeftMenuItem.orgMassPost:
      return {
        toFunction: ({ organization_id: orgId }) =>
          `/massPost/organization/${orgId}/`,
        displayCondition: (me) =>
          getFeatureUseStatus(
            me.organization.org_feature_use_limit,
            OrgFeatures.CAN_USE_MASS_POST
          ) !== FeatureUseStatus.CANNOT_USE,
        icon: "icon-mass-post",
        translationKey: "mass_post",
      };
    case LeftMenuItem.orgAnalysisCollections:
      return {
        toFunction: ({ organization_id: orgId }) =>
          `/analysis/organization/${orgId}/collections`,
        icon: "icon-chart",
        translationKey: "analysis",
      };
    case LeftMenuItem.orgRecommendations:
      return {
        toFunction: ({ organization_id: orgId }) =>
          `/recommendations/organization/${orgId}`,
        icon: "icon-suggestions",
        translationKey: "suggestions",
      };
    case LeftMenuItem.orgRecMessages:
      return {
        toFunction: ({ organization_id: orgId }) =>
          `/recMessages/organization/${orgId}`,
        icon: "icon-email",
        translationKey: "rec_messages",
      };
    case LeftMenuItem.orgTools:
      return {
        toFunction: ({ organization_id: orgId }) =>
          `/orgTools/organization/${orgId}`,
        icon: "icon-setting",
        translationKey: "tools",
      };
    case LeftMenuItem.facilityList:
      return {
        toFunction: ({ organization_id: orgId }) =>
          orgId ? `/locations/organization/${orgId}` : "/locations",
        icon: "icon-build",
        translationKey: "facility_list",
      };
    case LeftMenuItem.accountSettings:
      return {
        toFunction: ({ id }) => `/account/${id}`,
        icon: "icon-user-circle",
        translationKey: "account",
      };
    case LeftMenuItem.manageLocations:
      return {
        to: "/management/locations",
        icon: "icon-list",
        translationKey: "integration",
      };
    default:
      console.log("Unknown menu item:", menuItem);
  }
};

export const converMenuItemToElement = (
  item: LeftMenuItem,
  me?: AccountData
): React.ReactElement | undefined => {
  const menuItemData = generateLeftMenuProps(item);
  if (!menuItemData || !me) {
    return;
  }

  if (menuItemData.displayCondition && !menuItemData.displayCondition(me)) {
    return;
  }

  if (!menuItemData.to && !menuItemData.toFunction) {
    console.log("No destination for menu item:", item);
    return;
  }

  const destination = (
    menuItemData.toFunction ? menuItemData.toFunction(me) : menuItemData.to
  ) as string;

  return (
    <LeftMenuLinkItem
      key={item}
      to={destination}
      icon={menuItemData.icon}
      translationKey={menuItemData.translationKey}
    />
  );
};
