import { useQueryClient } from "@tanstack/react-query";

const useRefreshLocationData = (locationId: string) => {
  const queryClient = useQueryClient();

  /**
   * Refreshes a location's data by invalidating the queries associated with the location.
   * @param args - The type of data to refresh. If empty, all data will be refreshed.
   */
  const refresh = (...args: RefreshType[]) => {
    if (args.length === 0) {
      _refresh(ALL_REFRESH_TYPES);
    } else {
      _refresh(args);
    }
  };

  const _refresh = (types: RefreshType[]) => {
    types.forEach((t) => {
      queryClient.invalidateQueries({
        queryKey: [t, locationId]
      });
    });
  };

  return refresh;
};

export default useRefreshLocationData;

/**
 * The types of data that can be refreshed. Please feel free to add more as needed.
 */
export enum RefreshType {
  LOCATION = "location",
  LOCAL_POSTS = "location.localPosts",
  UN_REPLIED_REVIEWS = "unRepliedReviews"
}

const ALL_REFRESH_TYPES = Object.values(RefreshType).map(
  (t) => t as RefreshType
);
