import dayjs from "dayjs";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { GoogleInformation } from "../../../../models/GoogleInformation";
import DataSvc from "../../../../services/dataSvc";
import { downloadCsv } from "../../../../utils/csvUtils";
import "./styles.scss";

const now = dayjs();
const minDate = now.subtract(18, "month").add(2, "day").format("YYYY-MM-DD");

const maxDate = now.format("YYYY-MM-DD");

interface IMetricsAreaNewProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void;
  onLoaded(): void;
  commonErrorHandler: (err: string) => string | void;
  googleInformation: GoogleInformation | undefined;
  orgId?: number;
}
const MetricsAreaNew: React.FunctionComponent<IMetricsAreaNewProps> = ({
  onLoading,
  onLoaded,
  commonErrorHandler,
  googleInformation,
  orgId,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.areaNew.${key}`);

  const [areaName, setAreaName] = useState<string>("");
  const [locationName, setLocationName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [locationStatuses, setLocationStatuses] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const checkError = () => {
    let hasError = true;
    if (!orgId && !googleInformation?.accessToken) {
      setErrorMessage(t("please_login"));
    } else if (!locationName && !areaName) {
      setErrorMessage(t("please_input_either_location_or_place_name"));
    } else if (
      (startDate && dayjs(startDate).isBefore(minDate)) ||
      (startDate && endDate && dayjs(startDate).isAfter(dayjs(endDate)))
    ) {
      setErrorMessage(t("date_error"));
    } else {
      hasError = false;
    }

    return hasError;
  };

  const getAreaMetrics = (forWindows: boolean): void => {
    const hasError = checkError();
    if (hasError) {
      return;
    }

    onLoading();

    const args = {
      accountName: googleInformation?.accountName,
      accessToken: googleInformation?.accessToken,
      locationName,
      areaName,
      startDate,
      endDate,
    };

    if (orgId) {
      // Download from org tools screen
      ReactGA.event({
        category: "Org Tools",
        action: "Download Area Metrics CSV",
      });
    }

    const promise = orgId
      ? DataSvc.getToolsAreaMetricsForOrg({ ...args, orgId })
      : DataSvc.getToolsAreaMetrics(args);

    promise
      .then((data) => {
        const { length, start, end } = data;
        let { csvString } = data;

        if (!csvString) {
          setErrorMessage(_t("internaltoolsPage.public.no_facilities_found"));
          setLocationStatuses("");
          return;
        }

        csvString += `\n${_t(
          "internaltoolsPage.public.data_only_available_until",
          { maxDate: now.subtract(4, "day").format("YYYY-MM-DD") }
        )}`;

        const startDate = start.split("T")[0];
        const endDate = end.split("T")[0];

        downloadCsv(
          csvString.split("\n"),
          `${locationName ? locationName : areaName}(${startDate}-${endDate})`,
          forWindows
        );

        setLocationStatuses(
          _t("internaltoolsPage.areaNew.get_facilities_success", {
            length,
            start: startDate,
            end: endDate,
          })
        );
      })
      .catch((error) => {
        const decomposedError = commonErrorHandler(error);
        setErrorMessage(
          _t("internaltoolsPage.public.error", { error: decomposedError })
        );
      })
      .finally(onLoaded);
  };

  return (
    <React.Fragment>
      <h1>{t("main_title")}</h1>
      <section className="card">
        <p>{t("overall_title")}</p>
        <hr />
        <h3>{t("section_1_section_title_1")}</h3>
        <p>{t("section_1_title_1")}</p>
        <input
          placeholder={t("area_name_placeholder")}
          className="areaName"
          onChange={(e) => setAreaName(e.target.value)}
          value={areaName}
        />
        <hr />
        <h3>{t("section_1_section_title_2")}</h3>
        <p>{t("section_1_title_2")}</p>
        <input
          placeholder={t("location_placeholder")}
          className="locationName"
          onChange={(e) => setLocationName(e.target.value)}
          value={locationName}
        />
        <hr />
        <h3>{t("section_2_title_1")}</h3>
        <p>{t("section_2_title_2")}</p>
        <p>
          <label>{t("start_date")}</label>
          <input
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="comparisonStartDate"
            type="date"
            min={minDate}
            max={maxDate}
          />
          <label>{t("end_date")}</label>
          <input
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="comparisonEndDate"
            type="date"
            min={minDate}
            max={maxDate}
          />
        </p>
        <p>
          <button onClick={() => getAreaMetrics(false)}>{t("execute")}</button>
          &nbsp;
          <button onClick={() => getAreaMetrics(true)}>
            {t("execute")}(Windows)
          </button>
        </p>
        {errorMessage && <h3 id="error">{errorMessage}</h3>}
      </section>
      <h2>{t("get_information")}</h2>
      <section className="card">
        <div id="locationStatuses">{locationStatuses}</div>
      </section>
    </React.Fragment>
  );
};

export default withRouter(MetricsAreaNew);
