import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { ReviewSummaryCommentsChart } from "./ReviewSummaryCommentsChart";
import { ReviewSummarySummarySection } from "./ReviewSummarySummarySection";
import { useDateTranslation } from "../../../hooks/dateTranslation";
import { useMe } from "../../../hooks/queries";
import { LocationData } from "../../../models/LocationData";
import {
  LocationReviewSummaryHistory,
  LocationReviewSummary,
} from "../../../models/LocationReviewSummary";
import DataSvc from "../../../services/dataSvc";
import { ReviewSummarySelector } from "../../FacilityReviewSummariesComponents/ReviewSummarySelector";
import { QRCodeDisplay } from "../QRCodeDisplay";
import "./styles.scss";

interface IReviewSummaryProps {
  locationData: LocationData;
}

export const ReviewSummary: React.FunctionComponent<IReviewSummaryProps> = ({
  locationData,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string, params = {}) =>
    _t(`facilityDetailsPage.reviewSummary.${key}`, { ...params });

  const { translateDateRange } = useDateTranslation();

  const { data: me } = useMe();
  const organizationId =
    me?.type === "org_admin" ? me.organization_id : undefined;

  const { data: history, isFetched: isFetchedHistory } = useQuery<
    LocationReviewSummaryHistory[]
  >(["location-review-summary-history", locationData.id], () =>
    DataSvc.getLocationReviewSummaryHistory(Number(locationData.id))
  );

  const [reviewSummaryId, setReviewSummaryId] = React.useState<
    bigint | undefined
  >(undefined);

  const {
    data,
    isFetched: isFetchedReviewSummary,
  } = useQuery<LocationReviewSummary>(
    ["location-review-summary", locationData.id, reviewSummaryId],
    () =>
      DataSvc.getLocationReviewSummary(
        Number(locationData.id),
        Number(reviewSummaryId)
      ),
    { enabled: isFetchedHistory }
  );

  useEffect(() => {
    if (history && history.length > 0) {
      setReviewSummaryId(history?.[0]?.id);
    }
  }, [history]);

  return (
    <div className="white-border-panel reviewSummary-module padding32">
      <div className="review-summary-container">
        <div className="top-bar">
          <div className="title">
            <span>{t("section_title")}</span>
            {data && (
              <span>
                {translateDateRange(
                  // Cast to Date because the data is serialized as a string
                  new Date(data.start_date),
                  new Date(data.end_date)
                )}
              </span>
            )}
            <div className="rights">{t("section_note")}</div>
          </div>
        </div>
        {!data && <div className="content">{t("no_content")}</div>}
        {data && (
          <div className="content">
            <div className="summary-container">
              <ReviewSummarySummarySection summary={data} />
              <NavLink
                to={`/location/${locationData.id}${
                  organizationId ? `/organization/${organizationId}` : ""
                }/review-summaries/${Number(reviewSummaryId)}`}
                className="btn btn-blue"
              >
                {t("view_more")}
              </NavLink>
              {!!history && history.length > 0 && (
                <ReviewSummarySelector
                  history={history}
                  selectedId={reviewSummaryId}
                  onSelectId={setReviewSummaryId}
                />
              )}
            </div>
            {data && isFetchedReviewSummary && (
              <ReviewSummaryCommentsChart data={data} />
            )}
          </div>
        )}
      </div>
      <div className="qr-code-container">
        <QRCodeDisplay
          content={`https://search.google.com/local/writereview?placeid=${locationData.gbp_place_id}`}
          downloadFileName={`${locationData.name}__GBP新規口コミ投稿URL_QR-CODE`}
        />
      </div>
    </div>
  );
};
