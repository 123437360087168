import { Buffer } from "buffer";

import i18n from "../../../i18n";
import { isUrlValid } from "../../../utils/formatValidation";
import { DateTimeInterval } from "../../FormElement/DateTimeIntervalInput/utils";

export interface CreateLocalPostState {
  locationIds?: number[];
  media: File[];
  mediaError?: string;
  text: string;
  messageTextError?: string;
  languageCode: string;
  callToAction: CallToAction;
  topicType: LocalPostTopicType;
  geminiPrompt: string;
  event: LocalPostEvent;
  offer: {
    couponCode: string;
    couponCodeError?: string;
    redeemOnlineUrl: string;
    redeemOnlineUrlError?: string;
    termsConditions: string;
    termsAndConditionsError?: string;
  };
  postToInstagram: boolean;
}

export type LocalPostEvent = {
  title: string;
  titleError?: string;
  schedule: DateTimeInterval;
  scheduleError: EventScheduleError;
};

export type EventScheduleError = {
  dateError?: string;
  timeError?: string;
};

export type CallToAction = {
  actionType: ActionType;
  url: string;
  urlError?: string;
};

export enum ActionType {
  ACTION_TYPE_UNSPECIFIED = "ACTION_TYPE_UNSPECIFIED",
  BOOK = "BOOK",
  ORDER = "ORDER",
  SHOP = "SHOP",
  LEARN_MORE = "LEARN_MORE",
  SIGN_UP = "SIGN_UP",
  CALL = "CALL",
}

export enum LocalPostTopicType {
  LOCAL_POST_TOPIC_TYPE_UNSPECIFIED = "LOCAL_POST_TOPIC_TYPE_UNSPECIFIED",
  STANDARD = "STANDARD",
  EVENT = "EVENT",
  OFFER = "OFFER",
  ALERT = "ALERT",
}

export enum CreateLocalPostActionTypes {
  CHANGE_TOPIC_TYPE = "CHANGE_TOPIC_TYPE",
  CHANGE_MEDIA = "CHANGE_MEDIA",
  CHANGE_MEDIA_ERROR = "CHANGE_MEDIA_ERROR",
  CHANGE_EVENT_TITLE = "CHANGE_EVENT_TITLE",
  CHANGE_EVENT_SCHEDULE = "CHANGE_EVENT_SCHEDULE",
  CHANGE_EVENT_SCHEDULE_ERROR = "CHANGE_EVENT_SCHEDULE_ERROR",
  CHANGE_BODY = "CHANGE_BODY",
  CHANGE_OFFER_COUPON_CODE = "CHANGE_OFFER_COUPON_CODE",
  CHANGE_OFFER_REDEEM_ONLINE_URL = "CHANGE_OFFER_REDEEM_ONLINE_URL",
  CHANGE_OFFER_TERMS_CONDITIONS = "CHANGE_OFFER_TERMS_CONDITIONS",
  CHANGE_CALL_TO_ACTION_TYPE = "CHANGE_CALL_TO_ACTION_TYPE",
  CHANGE_CALL_TO_ACTION_URL = "CHANGE_CALL_TO_ACTION_URL",
  CHANGE_POST_TO_INSTAGRAM = "CHANGE_POST_TO_INSTAGRAM",
  CHANGE_GEMINI_PROMPT = "CHANGE_GEMINI_PROMPT",
}

export interface CreateLocalPostAction {
  type: CreateLocalPostActionTypes;
  payload: any;
}

export const t = (key: string) =>
  i18n.t(
    `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.errors.${key}`
  );

export const validateBody = (
  body: string,
  files: File[]
): string | undefined => {
  let error: string | undefined = undefined;
  if (body.length > 1500) {
    error = t("message_too_long");
  }
  if (body.trim().length < 1 && files.length === 0) {
    error = t("message_too_short");
  }
  return error;
};

export const validateCallToActionUrl = (
  cta: CallToAction
): string | undefined => {
  if (
    cta.actionType === ActionType.ACTION_TYPE_UNSPECIFIED ||
    cta.actionType === ActionType.CALL
  ) {
    return undefined;
  }

  if (!isUrlValid(cta.url)) {
    return t("url");
  }

  return undefined;
};

export const isCreateLocalPostStateValid = (
  state: CreateLocalPostState
): boolean => {
  // Return false if the topic type is not supported
  if (
    state.topicType === LocalPostTopicType.LOCAL_POST_TOPIC_TYPE_UNSPECIFIED ||
    state.topicType === LocalPostTopicType.ALERT
  ) {
    return false;
  }

  // All Offer posts are built on Event posts, which are built on Standard posts,
  // So we can check each section and then return false early when invalid,
  // and return true once we reach the topic type.

  // The only caveat is that offer posts do not have a time range, so we need to exclude time range validation for offer posts.

  const isStandardSectionValid = isLocalPostStandardSectionValid(state);
  if (!isStandardSectionValid) {
    return false;
  }
  if (state.topicType === LocalPostTopicType.STANDARD) {
    return true;
  }

  const isEventSectionValid = isLocalPostEventSectionValid(state);
  if (!isEventSectionValid) {
    return false;
  }
  if (state.topicType === LocalPostTopicType.EVENT) {
    return true;
  }

  const isOfferSectionValid = isLocalPostOfferSectionValid(state);
  if (!isOfferSectionValid) {
    return false;
  }
  return true;
};

const isLocalPostStandardSectionValid = (
  state: CreateLocalPostState
): boolean => {
  // Return false if there is a message text error
  if (state.messageTextError) return false;
  // Return false if there is no text or media
  if (state.text === "" && state.media.length === 0) return false;
  // Return false if there is a url error and the action type is not "ACTION_TYPE_UNSPECIFIED" or "CALL"
  if (
    state.callToAction.urlError &&
    state.callToAction.actionType !== ActionType.ACTION_TYPE_UNSPECIFIED &&
    state.callToAction.actionType !== ActionType.CALL
  ) {
    return false;
  }
  // Return true if the code reaches this point
  return true;
};

const isLocalPostEventSectionValid = (state: CreateLocalPostState): boolean => {
  // Return false if there no title
  if (state.event.title === "") {
    return false;
  }
  // Return false if there is a title error
  if (state.event.titleError) {
    return false;
  }
  // Return false if there is no start date or end date
  if (
    state.event.schedule.startDate === undefined ||
    state.event.schedule.endDate === undefined
  ) {
    return false;
  }
  // Return false if there is a date error
  if (state.event.scheduleError.dateError) {
    return false;
  }
  // Return false if there is a time error and the topic type is "EVENT"
  if (
    state.event.scheduleError.timeError &&
    state.topicType === LocalPostTopicType.EVENT
  ) {
    return false;
  }
  // Return true if the code reaches this point
  return true;
};

const isLocalPostOfferSectionValid = (state: CreateLocalPostState): boolean => {
  // Return false if there is a coupon code error
  if (state.offer.couponCodeError) {
    return false;
  }
  // Return false if there is a redeem online url error
  if (state.offer.redeemOnlineUrlError) {
    return false;
  }
  // Return false if there is a terms and conditions error
  if (state.offer.termsAndConditionsError) {
    return false;
  }
  // Return true if the code reaches this point
  return true;
};

interface GenerateAITextPayload {
  location_id: number;
  topic?: LocalPostTopicType;
  image_data?: {
    image_data_string: string;
    mime_type: string;
  };
  gemini_prompt?: string;
  extra_information?: {
    title: string;
    start_date?: string;
    end_date?: string;
  };
}

export const generatePayloadToGeneratePostTextWithGemini = async (
  state: CreateLocalPostState,
  locationId?: number
): Promise<GenerateAITextPayload | undefined> => {
  const { media, geminiPrompt } = state;

  if (!locationId || (media.length === 0 && geminiPrompt === "")) {
    return;
  }

  let data: GenerateAITextPayload = {
    location_id: Number(locationId),
  };

  if (media.length !== 0) {
    const image = media[0];
    const mediaBuffer = await image.arrayBuffer();
    const imageDataString = Buffer.from(mediaBuffer).toString("base64");

    data = {
      ...data,
      image_data: {
        image_data_string: imageDataString,
        mime_type: image.type,
      },
    };
  }

  if (geminiPrompt) {
    data.gemini_prompt = geminiPrompt;
  }

  if (state.topicType !== LocalPostTopicType.STANDARD) {
    data.topic = state.topicType;
    data.extra_information = {
      title: state.event.title,
      start_date: state.event.schedule.startDate?.format("YYYY/MM/DD HH:mm"),
      end_date: state.event.schedule.endDate?.format("YYYY/MM/DD HH:mm"),
    };
  }

  return data;
};
