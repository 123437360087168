import { useMutation } from "@tanstack/react-query";

import { LabelData } from "../../../models/LabelData";
import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";

const useLocationLabels = (
  locationId: string,
  locationData: LocationData | undefined
) => {
  const addLabelMutation = useMutation((payload: any) => {
    return DataSvc.addLocationLabel(payload.locationId, payload.labelId);
  });

  // on Add Label
  const onAddLabel = (label: LabelData) => {
    if (locationData) {
      addLabelMutation
        .mutateAsync({
          locationId,
          labelId: label.id
        })
        .then(() => {
          locationData.labels.push(label);
        });
    }
  };

  const deleteLabelMutation = useMutation((payload: any) => {
    return DataSvc.deleteLocationLabel(payload.locationId, payload.labelId);
  });

  // on Delete Label
  const onDeleteLabel = (indexLabel: number) => {
    if (locationData) {
      deleteLabelMutation
        .mutateAsync({
          locationId,
          labelId: locationData.labels[indexLabel].id
        })
        .then(() => {
          locationData.labels.splice(indexLabel, 1);
        });
    }
  };

  return {
    onAddLabel,
    onDeleteLabel
  };
};

export default useLocationLabels;
