import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

interface ModalPleaseWaitProps {
  title?: string;
  description?: string;
}

/**
 * An uncloseable modal that displays a message to the user while waiting for a response from the server.
 * Should be used when the request is expected to take a long time.
 * Can be easily extended in the future to display custom content by replacing title and/or description with optional props / children.
 */
export const ModalPleaseWait = ({
  title,
  description,
}: ModalPleaseWaitProps) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`modalPleaseWait.${key}`);

  return (
    <div className="modal-default modal-please-wait">
      <div className="modal-mains">
        <div className="content">
          <div className="top-title">{title || t("title")}</div>
          <div className="description">{description || t("description")}</div>
        </div>
      </div>
    </div>
  );
};
