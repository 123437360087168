import { useQuery } from "@tanstack/react-query";

import { AccountData } from "../../../models/AccountData";
import DataSvc from "../../../services/dataSvc";

export const useGhostOrganization = (me?: AccountData) => {
  const organizationId = me?.is_impersonating ? me?.organization_id : null;
  const { data: org } = useQuery(["organizations", organizationId], () => {
    if (!!organizationId) {
      return DataSvc.getOrganization({ organizationId });
    }
  });

  return org;
};
