import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useEffect } from "react";

import useRefetchLocationData from "../../../../containers/FacilityDetailsPage/hooks/useRefetchLocationData";
import { ReviewData } from "../../../../models/ReviewData";
import DataSvc from "../../../../services/dataSvc";

const useUnrepliedReviewsList = (locationId: string) => {
  const { data: unRepliedReviews, status } = useQuery(
    ["unRepliedReviews", locationId],
    () => {
      if (!locationId) return [];
      const now = dayjs();
      return DataSvc.getFacilityReviews({
        locationId: locationId,
        periodFromDate: dayjs(now).subtract(60, "days").format("YYYY-MM-DD"),
        periodToDate: dayjs(now).format("YYYY-MM-DD"),
        isReplied: 0,
        sort: "review_date",
        order: "desc"
      });
    }
  );

  const [unRepliedReviewList, setUnRepliedReviews] =
    React.useState<ReviewData[]>(unRepliedReviews);

  useEffect(() => {
    if (unRepliedReviews?.length > 0) {
      setUnRepliedReviews(unRepliedReviews);
    } else {
      setUnRepliedReviews([]);
    }
  }, [unRepliedReviews]);

  const { refetchLocationData } = useRefetchLocationData(locationId);
  const { refetchUnrepliedReviewsList } =
    useRefetchUnrepliedReviewsList(locationId);

  const onDeleteReviewItem = async (reviewId: string) => {
    setUnRepliedReviews((previousReviewList) =>
      previousReviewList.filter((r) => r.review_id !== reviewId)
    );

    refetchUnrepliedReviewsList();
    refetchLocationData();
  };

  return { unRepliedReviewList, onDeleteReviewItem, status };
};

const useRefetchUnrepliedReviewsList = (locationId: string) => {
  const queryClient = useQueryClient();

  const refetchUnrepliedReviewsList = () =>
    queryClient.invalidateQueries({
      queryKey: ["unRepliedReviews", locationId]
    });

  return { refetchUnrepliedReviewsList };
};

export default useUnrepliedReviewsList;
