/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { AccountData, AccountType } from "../../../models/AccountData";
import { MunicipalityData } from "../../../models/MunicipalityData";
import { OrganizationData } from "../../../models/OrganizationData";
import { Pagination } from "../../Pagination";
import { ListTopBar } from "../ListTopBar";

export interface IOrganizationManagementListProps {
  dataList: OrganizationData[];
  totalCount: number;
  filterFormData: any;
  organizationId: string;
  onChangeFilterFormData: (filterFormData: any) => void;
  onClickEdit: (organizationItem: OrganizationData) => void;
}

export const OrganizationManagementList: React.FunctionComponent<
  IOrganizationManagementListProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`organizationManagementPage.organizationManagementList.${key}`);

  const {
    dataList,
    totalCount,
    filterFormData, //organizationId,
    onChangeFilterFormData,
    onClickEdit,
  } = props;

  // get Expiration Date Label
  const getExpirationDateLabel = (dateStr: string) => {
    const date = new Date(dateStr);
    const today = new Date();

    const usedTime = date.valueOf() - today.valueOf();
    const daysLeft = Math.floor(usedTime / (24 * 3600 * 1000));

    const label = `${date.getFullYear()}/${
      date.getMonth() + 1 < 10 ? "0" : ""
    }${date.getMonth() + 1}/${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;

    return {
      daysLeft,
      label,
    };
  };

  // get Municipalities Label
  const getMunicipalitiesLabel = (dateArray: MunicipalityData[]) => {
    const label = `${
      dateArray.length > 0
        ? `${dateArray[0].name} ${
            dateArray.length > 1
              ? _t(
                  "organizationManagementPage.organizationManagementList.and_others",
                  { othersNumber: dateArray.length - 1 }
                )
              : ""
          }`
        : ""
    }`;

    return label;
  };

  const getRecentLoginLabel = (accounts: AccountData[]) => {
    let recentLogin = "";
    accounts.forEach((account) => {
      if (
        account.type === AccountType.ORG_ADMIN &&
        !_.isNull(account.last_login_at) &&
        (_.isEmpty(recentLogin) ||
          moment(account.last_login_at).isAfter(moment(recentLogin)))
      ) {
        recentLogin = moment(account.last_login_at).format(
          "YYYY/MM/DD HH:mm:ss"
        );
      }
    });
    if (_.isEmpty(recentLogin)) return "-";
    return moment(recentLogin).format("YYYY/MM/DD");
  };

  return (
    <>
      <div className="white-border-panel">
        <ListTopBar
          filterFormData={filterFormData}
          onChangeFilterFormData={(filterFormData: any) => {
            onChangeFilterFormData(filterFormData);
          }}
        />

        <div className="top-scrollbar">
          <div className="table-data scrollable">
            <div className="row-th">
              <div className="col-th width10">
                <div className="spacing">{t("organization_name")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("type")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("recent_login")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("location_health_index")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("location_login_rate")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("sign_up_rate")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("creation_date")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("expiration_date")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("municipalities")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("number_of_facilities")}</div>
              </div>
              <div className="col-th width10 center">
                <div className="spacing">{t("number_of_users")}</div>
              </div>
              <div className="col-th width-buttons">
                <div className="spacing"></div>
              </div>
            </div>
            {dataList
              .slice(0, filterFormData.numberPerPage)
              .map((item: OrganizationData, indexFacility: number) => (
                <div className="row-td" key={indexFacility}>
                  <div className="col-td width10">
                    <div className="spacing">
                      <span className="blue-txt">{item.name}</span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <span className="blue-txt">{t(item.type)}</span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <span className="blue-txt">
                        {`${getRecentLoginLabel(item.accounts)}`}
                      </span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <span className="blue-txt">
                        {`${item.health_index}%`}
                      </span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <span className="blue-txt">{`${item.login_rate}%`}</span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <span className="blue-txt">
                        {`${item.sign_up_rate}%`}
                      </span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <span className="blue-txt">
                        {!item.created_at
                          ? `-`
                          : _t(
                              "organizationManagementPage.organizationManagementList.creationdate",
                              {
                                date: moment(item.created_at).format(
                                  t("dateformat")
                                ),
                              }
                            )}
                      </span>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <div
                        className={`txt ${
                          getExpirationDateLabel(item.plan_expiry_date)
                            .daysLeft < 60
                            ? "red-txt"
                            : ""
                        }`}
                      >
                        {getExpirationDateLabel(item.plan_expiry_date).label}
                        <br />
                        {getExpirationDateLabel(item.plan_expiry_date)
                          .daysLeft >= 0 ? (
                          <>
                            {_t(
                              "organizationManagementPage.organizationManagementList.days_left",
                              {
                                daysLeft: getExpirationDateLabel(
                                  item.plan_expiry_date
                                ).daysLeft,
                              }
                            )}
                          </>
                        ) : (
                          <>{t("expired")}</>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">
                      <div className="txt">
                        {getMunicipalitiesLabel(item.municipalities)}
                      </div>
                    </div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">{item?.locations?.length}</div>
                  </div>
                  <div className="col-td width10 center">
                    <div className="spacing">{item?.accounts?.length}</div>
                  </div>
                  <div className="col-td center buttons-section">
                    <div className="spacing">
                      <a
                        className="btn btn-blue"
                        onClick={() => {
                          onClickEdit(item);
                        }}
                      >
                        {t("edit")}
                      </a>
                      <NavLink
                        className="btn btn-blue"
                        to={`/admin/organizations/${item.id}/accounts`}
                      >
                        {t("edit_members")}
                      </NavLink>
                      <NavLink
                        className="btn btn-blue"
                        to={`/dashboard/organization/${item.id}`}
                      >
                        {t("see_screens")}
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <Pagination
        filterFormData={filterFormData}
        totalRowsNumber={totalCount}
        onChangeFilterFormData={(filterFormData: any) => {
          onChangeFilterFormData(filterFormData);
        }}
      />
    </>
  );
};

export default OrganizationManagementList;
